import styled from "styled-components";
import { colors } from "../../../variables";

export const ModalContainer = styled.div`
  background-color: ${colors.textWhite};
  width: 300px;
  margin: auto;
  text-align: center;
  padding: 14px;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
`;
export const CloseButton = styled.button`
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 4px;
  height: 30px;
  width: 30px;
  line-height: 22px;
  font-family: inherit;
  font-weight: 600;
  font-size: 30px;
  padding: 0 0 6px 2px;
  border-radius: 50%;
  background-color: ${colors.lightGrey};
  color: ${colors.textBlack};
  border: 0;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  @media (max-width: 767px) {
    top: 4px;
    left: 8px;
    height: 30px;
    width: 30px;
    font-size: 26px;
    line-height: 20px;
    padding: 0 0 4px 2px;
    padding: 0 0 4px 2px;
  }
`;

export const ModalContentWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
