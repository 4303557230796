import { combineReducers } from "redux";
import missions from "./MissionsDataReducers";
import UserInfoReducer from "./UserInfoReducer";
import PromoCodeReducer from "./PromoCodeReducer";
import UserActionsReducers from "./UsersActionsReducers";
import HistoryDataReducers from "./HistoryDataReducers";
import ReferralDataReducers from "./ReferralDataReducers";
import ButtonClaimReducers from "./ButtonClaimReducers";
export default combineReducers({
  missions,
  UserInfo: UserInfoReducer,
  PromoCode: PromoCodeReducer,
  startMission: UserActionsReducers,
  missionsComplete: HistoryDataReducers,
  referralMission: ReferralDataReducers,
  claimModals: ButtonClaimReducers
});
