import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// redux actions
import {
  addPromoCode,
  submitPromoCode,
  resetState
} from "../../redux/actions/AddPromoCode";
import {
  toggleLoadingModal,
  toggleSuccessModal,
  toggleErrorModal
} from "../../redux/actions/ModalsActions";
// core components
import Header from "../FormHeader";
import Modal from "../Modals/Modal";
import SpinnerModal from "../Modals/SpinnerModal";
import SuccessModal from "../Modals/SuccessModal";
import NonsuccessModal from "../Modals/NonsuccessModal";
import Arrow from "../../assets/images/right-arrow.png";
import { checkForApiErrors } from "../../utils/helpers";

import {
  StyledForm,
  FormGroup,
  StyledUserInput,
  SubmitButton
} from "./style.css";

const PromoCodeForm = props => {
  const {
    language,
    userPoints,
    userKey,
    promoCode,
    success,
    error,
    message,
    showLoadingModal,
    showSuccessModal,
    showErrorModal,
    UserReferralCode,
    addPromoCode,
    submitPromoCode,
    toggleLoadingModal,
    toggleSuccessModal,
    toggleErrorModal,
    resetState
  } = props;
  const [errorMessage, setErrorMessage] = useState({});
  const handelSubmit = e => {
    e.preventDefault();
    toggleLoadingModal();
    submitPromoCode(promoCode);
  };

  const handelChange = e => {
    e.preventDefault();
    addPromoCode(e.target.value);
  };

  useEffect(() => {
    resetState();
  }, [resetState]);

  useEffect(() => {
    if (UserReferralCode) {
      addPromoCode(UserReferralCode);
    }
  }, [UserReferralCode, addPromoCode]);

  useEffect(() => {
    if (error) {
      let err = checkForApiErrors(error);
      // Check if there is no Error case matched, Set the msg to the error msg from API
      if (!err.en) {
        setErrorMessage(message);
      } else {
        setErrorMessage(err);
      }
    }
  }, [error, message]);

  return (
    <>
      <Header userPoints={userPoints} userKey={userKey} />
      <StyledForm onSubmit={handelSubmit}>
        <fieldset>
          <legend className="visually-hidden">promo code</legend>
          <FormGroup unsuccess={error} success={success} language={language}>
            <label htmlFor="promo-code">
              {language === "en" ? "Code" : "كود"}
            </label>
            <StyledUserInput
              type="text"
              name="promoCode"
              id="promo-code"
              onChange={handelChange}
              language={language}
              placeholder={
                language === "en" ? "Enter a promo code" : "أدخل رمزًا ترويجيًا"
              }
              value={promoCode}
              unsuccess={error}
              success={success}
            />
          </FormGroup>
        </fieldset>
        <SubmitButton language={language} disabled={!promoCode}>
          <img src={Arrow} alt="" />
        </SubmitButton>
      </StyledForm>
      {showLoadingModal && (
        <Modal>
          <SpinnerModal />
        </Modal>
      )}
      {showSuccessModal && (
        <Modal onClose={() => toggleSuccessModal()}>
          <SuccessModal promocode />
        </Modal>
      )}
      {showErrorModal && (
        <Modal onClose={() => toggleErrorModal()}>
          <NonsuccessModal message={errorMessage} promocode />
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    language: state.UserInfo.language,
    userPoints: state.UserInfo.userPoints,
    userKey: state.UserInfo.userKey,
    UserReferralCode: state.UserInfo.UserReferralCode,
    promoCode: state.PromoCode.promoCode,
    success: state.PromoCode.success,
    error: state.PromoCode.error,
    message: state.PromoCode.errorMessage,
    showLoadingModal: state.PromoCode.showLoadingModal,
    showSuccessModal: state.PromoCode.showSuccessModal,
    showErrorModal: state.PromoCode.showErrorModal
  };
};

export default connect(mapStateToProps, {
  addPromoCode,
  submitPromoCode,
  toggleLoadingModal,
  toggleSuccessModal,
  toggleErrorModal,
  resetState
})(PromoCodeForm);
