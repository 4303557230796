import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Coin from "../CoinComponent";
import leftArrow from "../../assets/images/right-arrow.png";
import { handleWindowResize } from "../../utils/helpers";

import { HeaderContainer, TotalPoints } from "./style.css";

const coinData = handleWindowResize("header");

const FormHeader = ({
  language,
  userPoints,
  userKey,
  referralCode,
  UserReferralCode
}) => {
  return (
    <HeaderContainer primary={language}>
      <span secondary={language}>
        <Link
          to={`/?locale=${language}&userPoints=${userPoints}&key=${userKey}&code=${referralCode}&referral_code=${UserReferralCode}`}
        >
          <img src={leftArrow} alt="" secondary={language} />
          <span>
            {language === "en" ? "Add Promotions" : "أضف رمزًا ترويجيًا"}
          </span>
        </Link>
      </span>
      <TotalPoints>
        <Coin
          size={coinData.size}
          font={coinData.font}
          lineHeight={coinData.lineHeight}
        />
        <span>{Number(userPoints).toLocaleString("en-US")}</span>
      </TotalPoints>
    </HeaderContainer>
  );
};

const mapStateToProps = state => {
  return {
    language: state.UserInfo.language,
    userPoints: state.UserInfo.userPoints,
    userKey: state.UserInfo.userKey,
    referralCode: state.UserInfo.referralCode,
    UserReferralCode: state.UserInfo.UserReferralCode
  };
};

export default connect(mapStateToProps)(FormHeader);
