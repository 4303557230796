import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { ModalContentWrapper, ModalContent, ModalContainer } from "./style.css";

const modalRoot = document.getElementById("modal-root");
const modalContainer = document.createElement("div");
modalContainer.id = "modal-container";
document.body.appendChild(modalContainer);

class Modal extends Component {
  static propTypes = {
    onClose: PropTypes.func
  };

  static defaultProps = {
    overlayCloses: true,
    onClose: () => null
  };

  closeModal = () => {
    this.props.onClose();
  };

  componentDidMount() {
    modalRoot.appendChild(modalContainer);
  }

  componentWillUnmount() {
    modalRoot.removeChild(modalContainer);
  }

  render() {
    return ReactDOM.createPortal(
      <ModalContentWrapper>
        <ModalContainer>{this.props.children}</ModalContainer>
        <ModalContent onClick={this.closeModal} />
      </ModalContentWrapper>,
      modalContainer
    );
  }
}

export default Modal;
