import { FETCH_MISSIONS, ERROR_FETCH_MISSIONS } from "../constants/ActionTypes";

const initialState = {
  missions: [],
  missionsGames: [],
  missionSurvey: [],
  moreMissions: [],
  missionDownLoad: [],
  errorFetchMissions: false
};
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MISSIONS:

      let featuredMissions = action.payload.filter(item => item.isFeatured);

      let filterByType = type => {
        return featuredMissions.filter(
          item => {
            return item.category.slug.toLowerCase() === type.toLowerCase()
          }
        );
      };

      let missionsGames = filterByType("Games");
      let missionSurvey = filterByType("surveys");
      let missionDownLoad = filterByType("Downloads");
      let concatMission = [...missionSurvey, ...missionsGames, ...missionDownLoad];

      let moreMissions = action.payload.filter(item => {
        return !concatMission.some(result => item.id === result.id);
      });

      return {
        ...state,
        missionsGames,
        missionSurvey,
        missionDownLoad,
        moreMissions,
        errorFetchMissions: false
      };
    case ERROR_FETCH_MISSIONS:
      return {
        ...state,
        errorFetchMissions: true
      }
    default:
      return state;
  }
};
