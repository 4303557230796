import { baseURL } from "../../config";
import { FETCH_MISSIONS } from "../constants/ActionTypes";

export const fetchMissions = () => async dispatch => {
  await baseURL
    .get("/missions", {
      headers: {
        "Cache-Control": "no-cache",
        Authorization: `Key ${new URLSearchParams(window.location.search).get(
          "key"
        )}`
      }
    })
    .then(res => {
      dispatch({
        type: FETCH_MISSIONS,
        payload: res.data
      });
    })
    .catch(err => {
      // dispatch({
      //   type: ERROR_FETCH_MISSIONS
      // })
    });
};
