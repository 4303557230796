import axios from "axios";

const dev = {
  url: {
    API_URL: "https://wasla-missions.firebaseapp.com/",
    baseURL: "https://dev.missions.apiv2.waslabrowser.com/",
    promoCodeBaseURL: "https://dev.promocodes.api.waslabrowser.com",
    referralBaseURL: "https://dev.referral.waslabrowser.com/user"
  }
};

const prod = {
  url: {
    API_URL: "https://wasla-missions.firebaseapp.com/",
    baseURL: "https://missions.apiv2.waslabrowser.com/",
    promoCodeBaseURL: "https://promocodes.api.waslabrowser.com/",
    referralBaseURL: "https://referral.api.waslabrowser.com/user"
  }
};

const config = process.env.NODE_ENV === "production" ? prod : dev;

export const baseURL = axios.create({
  baseURL: config.url.baseURL
});

export const PromoCodeBaseURL = axios.create({
  baseURL: config.url.promoCodeBaseURL
});

export const ReferralBaseURL = axios.create({
  baseURL: config.url.referralBaseURL
});
