import {
  GET_USER_INFO,
  ADD_REFERRAL_POINTS,
  ADD_MISSION_POINTS,
  ADD_PROMOCODE_REDEEM_POINTS,
} from "../constants/ActionTypes";

const initialState = {
  language: "en",
  userPoints: 0,
  userKey: "",
  referralCode: "",
  UserReferralCode: "",
  restricted: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_INFO:
      return {
        ...state,
        language: action.payload.language,
        userPoints: action.payload.userPoints,
        userKey: action.payload.userKey,
        referralCode: action.payload.referralCode,
        UserReferralCode: action.payload.UserReferralCode,
        restricted: !(action.payload.restricted === "true"),
      };

    case ADD_REFERRAL_POINTS:
      return {
        ...state,
        userPoints: action.payload,
      };

    case ADD_MISSION_POINTS:
      return {
        ...state,
        userPoints: Number(state.userPoints) + Number(action.payload),
      };
    case ADD_PROMOCODE_REDEEM_POINTS:
      return {
        ...state,
        userPoints: Number(state.userPoints) + Number(action.payload),
      };
    default:
      return state;
  }
};
