import {
  ADD_PROMO_CODE,
  PROMO_CODE_SUCCESS,
  PROMO_CODE_ERROR,
  RESET_STATE,
  ADD_PROMOCODE_REDEEM_POINTS
} from "../constants/ActionTypes";
import { PromoCodeBaseURL } from "../../config";

export const addPromoCode = code => {
  return {
    type: ADD_PROMO_CODE,
    payload: code
  };
};

export const submitPromoCode = code => async dispatch => {
  await PromoCodeBaseURL.post(
    "/redeem/",
    {
      code
    },
    {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Key ${new URLSearchParams(window.location.search).get(
          "key"
        )}`
      }
    }
  )
    .then(response => {
      dispatch({
        type: PROMO_CODE_SUCCESS,
        payload: response.status
      });
      dispatch({
        type: ADD_PROMOCODE_REDEEM_POINTS,
        payload: response.data.points
      });
    })
    .catch(error => {
      dispatch({
        type: PROMO_CODE_ERROR,
        payload: {
          errorCode: error.response.data.error.code,
          errorMessage: error.response.data.error.message
        }
      });
    });
};

export const resetState = () => {
  return {
    type: RESET_STATE
  };
};
