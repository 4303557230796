import React from "react";
import { CoinWrapper } from "./style.css";

const Coin = ({ size, font, lineHeight }) => {
  return (
    <CoinWrapper
      width={size}
      height={size}
      fontSize={font}
      lineHeight={lineHeight}
    >
      1
    </CoinWrapper>
  );
};

export default Coin;
