import styled from "styled-components";
import { colors } from "../../../variables";

export const CheckMarkContainer = styled.div`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  background-color: ${colors.success};
  justify-content: center;
`;
export const CheckMark = styled.div`
  display: inline-block;
  &:after {
    content: "";
    display: block;
    width: 13px;
    height: 34px;
    border: solid ${colors.textWhite};
    border-width: 0 5px 5px 0;
    transform: rotate(45deg);
  }
`;
export const TextHead = styled.h4`
  color: ${colors.textBlack};
  margin-top: 10px;
  margin-bottom: 0;
`;
export const Text = styled.p`
  color: ${colors.textBlack};
  margin-top: 0;
  margin-bottom: 0;
`;
