// Check for window inner width and set the Coin component size based on it

export const handleWindowResize = component => {
  let innerWidth = window.innerWidth;
  let coinDimensions = {};

  if (component === "header") {
    switch (innerWidth) {
      case 1024:
        coinDimensions = {
          size: 22,
          font: 16,
          lineHeight: 15
        };
        break;

      case 768:
        coinDimensions = {
          size: 20,
          font: 14,
          lineHeight: 13
        };

        break;

      default:
        coinDimensions = {
          size: 17,
          font: 11,
          lineHeight: 13
        };
        break;
    }
  } else {
    switch (innerWidth) {
      case 1024:
        coinDimensions = {
          size: 20,
          font: 13,
          lineHeight: 14
        };
        break;

      case 768:
        coinDimensions = {
          size: 17,
          font: 12,
          lineHeight: 15
        };

        break;

      default:
        coinDimensions = {
          size: 15,
          font: 10,
          lineHeight: 13
        };
        break;
    }
  }
  return coinDimensions;
};

// Check for different error cases returned after promo code submitting
export const checkForApiErrors = error => {
  let errorMessage = {};
  switch (error) {
    case "CODE_NOT_FOUND":
      errorMessage = {
        en: "This code is not valid. Please try another one.",
        ar: "الكود الذى ادخلته غير صحيح. جرب ادخال كود اخر"
      };
      break;

    case "SERVER_ERROR":
      errorMessage = {
        en: "Something went wrong please try again",
        ar: "خطأ فى الاتصال. يرجى المحاولة مرة أخرى"
      };
      break;

    case "CODE_EXPIRED":
      errorMessage = {
        en: "Code has been expired",
        ar: "الكود انتهى"
      };
      break;

    case "CODE_USAGE_LIMIT_EXCEEDED":
      errorMessage = {
        en: "Code usage has been exceeded limit",
        ar: "الكود تم استخدامه أكتر من مرة"
      };
      break;

    case "CAMPAIGN_USAGE_LIMIT_EXCEEDED":
      errorMessage = {
        en: "Campaign has been expired",
        ar: "الحملة انتهت"
      };
      break;

    default:
      errorMessage = {
        en: "",
        ar: ""
      };
      break;
  }

  return errorMessage;
};
