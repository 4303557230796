export const FETCH_MISSIONS = "FETCH_MISSIONS";
export const ERROR_FETCH_MISSIONS = "ERROR_FETCH_MISSIONS";

export const GET_USER_INFO = "GET_USER_INFO";

export const ADD_PROMO_CODE = "ADD_PROMO_CODE";
export const SUBMIT_PROMO_CODE = "SUBMIT_PROMO_CODE";
export const PROMO_CODE_SUCCESS = "PROMO_CODE_SUCCESS";
export const PROMO_CODE_ERROR = "PROMO_CODE_ERROR";

export const TOGGLE_LOADING_MODAL = "TOGGLE_LOADING_MODAL";
export const TOGGLE_SUCCESS_MODAL = "TOGGLE_SUCCESS_MODAL";
export const TOGGLE_ERROR_MODAL = "TOGGLE_ERROR_MODAL";
export const RESET_STATE = "RESET_STATE";

export const SUCCESS_START_MISSION_ACTIONS = "SUCCESS_START_MISSION_ACTIONS";
export const ERROR_START_MISSION_ACTIONS = "ERROR_START_MISSION_ACTIONS";

export const RESET_STATE_USER_ACTION = "RESET_STATE_USER_ACTION";
export const FETCH_MISSIONS_HISTORY = "FETCH_MISSIONS_HISTORY";
export const SHOW_MISSION_ERROR_MODAL = "SHOW_MISSION_ERROR_MODAL";
export const FETCH_REFERRAL = "FETCH_REFERRAL";
export const CLAIM_LOADING_MODAL = "CLAIM_LOADING_MODAL";
export const CLAIM_SUCCESS_MODAL = "CLAIM_SUCCESS_MODAL";
export const CLAIM_ERROR_MODAL = "CLAIM_ERROR_MODAL";

export const ADD_REFERRAL_POINTS = "ADD_REFERRAL_POINTS";
export const ADD_MISSION_POINTS = "ADD_MISSION_POINTS";

export const SHOW_ANONYMOUS_ERROR_MODAL = "SHOW_ANONYMOUS_ERROR_MODAL";

export const RESET_STATUS_CODE = "RESET_STATUS_CODE";

export const ADD_PROMOCODE_REDEEM_POINTS = "ADD_PROMOCODE_REDEEM_POINTS";

export const TOGGLE_LOADING_PAGE_MODAL = "TOGGLE_LOADING_PAGE_MODAL";
