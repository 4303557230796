import {
  ADD_PROMO_CODE,
  PROMO_CODE_SUCCESS,
  PROMO_CODE_ERROR,
  TOGGLE_LOADING_MODAL,
  TOGGLE_SUCCESS_MODAL,
  TOGGLE_ERROR_MODAL,
  RESET_STATE
} from "../constants/ActionTypes";

const initialState = {
  promoCode: "",
  success: null,
  error: null,
  errorMessage: null,
  showLoadingModal: false,
  showSuccessModal: false,
  showErrorModal: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PROMO_CODE:
      return {
        ...state,
        promoCode: action.payload
      };

    case TOGGLE_LOADING_MODAL:
      return {
        ...state,
        showLoadingModal: true
      };

    case PROMO_CODE_SUCCESS:
      return {
        ...state,
        success: action.payload,
        showLoadingModal: false,
        showSuccessModal: true
      };

    case PROMO_CODE_ERROR:
      return {
        ...state,
        error: action.payload.errorCode,
        errorMessage: action.payload.errorMessage,
        showLoadingModal: false,
        showErrorModal: true
      };

    case TOGGLE_SUCCESS_MODAL:
      return {
        ...state,
        showSuccessModal: false
      };

    case TOGGLE_ERROR_MODAL:
      return {
        ...state,
        showErrorModal: false
      };

    case RESET_STATE:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
};
