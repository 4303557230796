import React from "react";
import { connect } from "react-redux";

import { CheckMarkContainer, CheckMark, TextHead, Text } from "./style.css";

const SuccessModal = ({ language, promocode }) => {
  return (
    <>
      <CheckMarkContainer>
        <CheckMark />
      </CheckMarkContainer>
      <TextHead>{language === "en" ? "Complete" : "اكتمل"}</TextHead>
      {promocode ? (
        <Text>
          {language === "en"
            ? "Code successfully added"
            : "تمت إضافة الرمز بنجاح"}
        </Text>
      ) : (
        <Text>
          {language === "en"
            ? "Mission Redemption Completed!"
            : "تم تجميع النقاط بنجاح"}
        </Text>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    language: state.UserInfo.language
  };
};

export default connect(mapStateToProps)(SuccessModal);
