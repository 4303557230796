import styled from "styled-components";
import { colors } from "../../variables";

export const CoinWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.width ? `${props.width}px` : "15px")};
  height: ${props => (props.height ? `${props.height}px` : "15px")};
  line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px` : "13px"};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : "10px")};
  text-align: center;
  margin: 0 4px;
  background: linear-gradient(
    135deg,
    ${colors.secondaryColor},
    ${colors.secondaryColor} 50%,
    ${colors.primaryColor}
  );
  border: 1px solid ${colors.textWhite};
  border-radius: 50%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  color: ${colors.textWhite};
  @media (min-width: 600px) {
    margin: 0 8px;
  }
`;
