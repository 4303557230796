import React from "react";
import { connect } from "react-redux";

import {
  SpinnerItem,
  SpinnerStyle,
  Container,
  TextHead,
  Text
} from "./style.css";

const SpinnerModal = ({ language }) => {
  return (
    <>
      <SpinnerStyle>
        <Container>
          <SpinnerItem />
          <SpinnerItem />
          <SpinnerItem />
          <SpinnerItem />
          <SpinnerItem />
          <SpinnerItem />
          <SpinnerItem />
          <SpinnerItem />
          <SpinnerItem />
          <SpinnerItem />
          <SpinnerItem />
          <SpinnerItem />
        </Container>
      </SpinnerStyle>
      <TextHead>{language === "en" ? "Processing" : "تحميل"}</TextHead>
      <Text>
        {language === "en"
          ? "Please wait a few moments"
          : "من فضلك انتظر دقيقة"}
      </Text>
    </>
  );
};

const mapStateToProps = state => {
  return {
    language: state.UserInfo.language
  };
};

export default connect(mapStateToProps)(SpinnerModal);
