import {
  CLAIM_LOADING_MODAL,
  CLAIM_SUCCESS_MODAL,
  CLAIM_ERROR_MODAL
} from "../constants/ActionTypes";

export const claimLoadingModal = () => {
  return {
    type: CLAIM_LOADING_MODAL
  };
};

export const claimSuccessModal = () => {
  return {
    type: CLAIM_SUCCESS_MODAL
  };
};

export const claimErrorModal = () => {
  return {
    type: CLAIM_ERROR_MODAL
  };
};
