import {
  TOGGLE_LOADING_MODAL,
  TOGGLE_SUCCESS_MODAL,
  TOGGLE_ERROR_MODAL
} from "../constants/ActionTypes";

export const toggleLoadingModal = () => {
  return {
    type: TOGGLE_LOADING_MODAL
  };
};

export const toggleSuccessModal = () => {
  return {
    type: TOGGLE_SUCCESS_MODAL
  };
};

export const toggleErrorModal = () => {
  return {
    type: TOGGLE_ERROR_MODAL
  };
};
