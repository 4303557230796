import { ReferralBaseURL } from "../../config";
import {
  FETCH_REFERRAL,
  CLAIM_ERROR_MODAL,
  ADD_REFERRAL_POINTS
} from "../constants/ActionTypes";
import { claimSuccessModal } from "./ButtonClaimActions";

export const fetchReferral = () => async dispatch => {
  await ReferralBaseURL.get("/referral/", {
    headers: {
      "Cache-Control": "no-cache",
      Authorization: new URLSearchParams(window.location.search).get("key")
    }
  })
    .then(res => {
      dispatch({
        type: FETCH_REFERRAL,
        payload: res.data
      });
    })
    .catch(err => {
      // dispatch({
      //   type: ERROR_FETCH_MISSIONS
      // })
    });
};

export const claimReferralAction = () => async dispatch => {
  await ReferralBaseURL.post(
    "referral/claim/",
    {},
    {
      headers: {
        Authorization: new URLSearchParams(window.location.search).get("key")
      }
    }
  )
    .then(res => {
      dispatch(claimSuccessModal());
      dispatch(fetchReferral());
      dispatch({
        type: ADD_REFERRAL_POINTS,
        payload: res.data.user_points
      });
    })
    .catch(err => {
      dispatch({
        type: CLAIM_ERROR_MODAL
      });
    });
};
