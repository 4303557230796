import styled from "styled-components";
import { colors } from "../../variables";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 58.72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  font-size: 1rem;
  background: linear-gradient(
    270deg,
    ${colors.secondaryDark},
    ${colors.secondaryColor}
  );
  @media (min-width: 576px) {
    height: 70px;
    padding: 10px 32px;
  }
  @media (min-width: 1024px) {
    padding: 40px 64px;
  }
  & > span {
    display: flex;
    align-items: center;
    font-size: inherit;
    font-weight: inherit;
    color: ${colors.textWhite};
    letter-spacing: 1px;
    & a {
      display: flex;
      align-items: center;
      font-size: 1.3em;
      text-decoration: none;
      color: ${colors.textWhite};
      & img {
        display: flex;
        align-items: center;
        margin: 6px 12px 6px 0;
        transform: rotate(180deg);
        @media (min-width: 576px) {
          margin-inline-end: 10px;
        }
      }
    }
  }
`;

export const TotalPoints = styled.div`
  background-color: ${colors.textWhite};
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  width: 114px;
  height: 36px;
  display: flex;
  align-items: center;
  color: ${colors.textBlack};
  padding: 0 6px;
  justify-content: center;
  @media (min-width: 576px) {
    height: 44px;
  }
  & span:last-of-type {
    font-size: 16px;
    font-weight: bold;
    @media (min-width: 576px) {
      font-size: 18px;
    }
  }
`;
