import { FETCH_MISSIONS_HISTORY } from "../constants/ActionTypes";

const initialState = {
  missionsComplete: []
};
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MISSIONS_HISTORY:
      let filterNull = action.payload.filter(item => {
        let mission = item && item.mission;
        return mission;
      });

      let missionsComplete = filterNull.map(mission => {
        let newMission = mission.mission;
        newMission.claimedPoints = mission.claimedPoints;
        return newMission;
      });

      return {
        ...state,
        missionsComplete,
        errorFetchMissions: false
      };
    default:
      return state;
  }
};
