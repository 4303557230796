export const colors = {
  primaryColor: "#f94d59", // light red
  secondaryColor: "#fec70c", // canary yellow
  secondaryDark: "#f9a51c", // yellowish orange
  lightYellow: "#ffde6e",
  crimsonRed: "#D75A4A",
  seaBlue: "#26c1f4",
  seaBlueLight: "#60d8ff",
  skyBlue: "#58ACFF",
  darkSkyBlue: "#535BD0",
  success: "#6AC259", // grass green
  textWhite: "#FFFFFF",
  textBlack: "#000000",
  darkGrey: "#324a58",
  grey: "#D6D6D6",
  secondaryGrey: "#656565",
  lightGrey: "#f4f5f7",
  midGrey: "#a6a6a6"
};
