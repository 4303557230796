import { GET_USER_INFO } from "../constants/ActionTypes";

// Get the User Info From URL query string
export const getUserDataFromURL = () => {
  return {
    type: GET_USER_INFO,
    payload: {
      language:
        new URLSearchParams(window.location.search).get("locale") || "en",
      userPoints: new URLSearchParams(window.location.search).get("userPoints"),
      userKey: new URLSearchParams(window.location.search).get("key"),
      referralCode: new URLSearchParams(window.location.search).get("code"),
      restricted: new URLSearchParams(window.location.search).get("restricted"),
      UserReferralCode: new URLSearchParams(window.location.search).get(
        "referral_code"
      ),
    },
  };
};
