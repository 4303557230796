import {
  CLAIM_LOADING_MODAL,
  CLAIM_SUCCESS_MODAL,
  CLAIM_ERROR_MODAL,
  RESET_STATE
} from "../constants/ActionTypes";

const initialState = {
  claimLoading: false,
  claimSuccess: false,
  claimError: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLAIM_LOADING_MODAL:
      return {
        ...state,
        claimLoading: true
      };
    case CLAIM_SUCCESS_MODAL:
      return {
        ...state,
        claimSuccess: !state.claimSuccess,
        claimLoading: false
      };
    case CLAIM_ERROR_MODAL:
      return {
        ...state,
        claimLoading: false,
        claimError: !state.claimError
      };
    case RESET_STATE:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};
