import React, { useEffect, lazy, Suspense } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
// redux actions
import { fetchMissions } from "../redux/actions/FetchMissions";
import { fetchMissionsHistory } from "../redux/actions/FetchMissionsHistory";
import { getUserDataFromURL } from "../redux/actions/GetUserInfo";
import { fetchReferral } from "../redux/actions/FetchReferral";
// core component
import Spinner from "../components/Spinner";
import PromoCodeForm from "../components/PromoCodeForm";

const Tabs = lazy(() => import("../components/Tabs"));

function App(props) {
  const {
    fetchMissions,
    getUserDataFromURL,
    fetchMissionsHistory,
    fetchReferral,
    restricted,
  } = props;

  useEffect(() => {
    fetchMissions();
    getUserDataFromURL();
    fetchReferral();
    fetchMissionsHistory();
  }, [fetchMissions, fetchMissionsHistory, fetchReferral, getUserDataFromURL]);

  return (
    <Router>
      <Route
        path="/"
        exact
        render={() => (
          <Suspense fallback={<Spinner />}>
            <Tabs />
          </Suspense>
        )}
      />
      {restricted && (
        <Route exact path="/PromoCodeForm" render={() => <PromoCodeForm />} />
      )}
    </Router>
  );
}
const mapStateToProps = (state) => {
  return {
    restricted: state.UserInfo.restricted,
  };
};
export default connect(mapStateToProps, {
  fetchMissions,
  getUserDataFromURL,
  fetchMissionsHistory,
  fetchReferral,
})(App);
App.propTypes = {
  fetchMissions: propTypes.func,
  getUserDataFromURL: propTypes.func,
  fetchMissionsHistory: propTypes.func,
  fetchReferral: propTypes.func,
  restricted: propTypes.bool,
};
