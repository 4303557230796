import styled from "styled-components";
import { colors } from "../../../variables";

export const NonsuccessContainer = styled.div`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Nonsuccess = styled.img`
  width: 200px;
  height: 200px;
`;
export const TextHead = styled.h4`
  color: ${colors.textBlack};
  margin-top: 10px;
  margin-bottom: 0;
`;
export const Text = styled.p`
  color: ${colors.textBlack};
  margin-top: 0;
  margin-bottom: 0;
`;
