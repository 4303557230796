import {
  SUCCESS_START_MISSION_ACTIONS,
  ERROR_START_MISSION_ACTIONS,
  RESET_STATE_USER_ACTION,
  SHOW_MISSION_ERROR_MODAL,
  SHOW_ANONYMOUS_ERROR_MODAL,
  RESET_STATUS_CODE,
  TOGGLE_LOADING_PAGE_MODAL
} from "../constants/ActionTypes";

const initialState = {
  success: false,
  error: false,
  showErrorModal: false,
  statusCode: null,
  showAnonymousModal: false,
  showLoadingPage: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_START_MISSION_ACTIONS:
      return {
        ...state,
        success: true,
        error: false
      };
    case ERROR_START_MISSION_ACTIONS:
      return {
        ...state,
        statusCode: action.payload.response.status,
        error: true
      };
    case SHOW_MISSION_ERROR_MODAL:
      return {
        ...state,
        showErrorModal: !state.showErrorModal,
        showLoadingPage: false
      };
    case RESET_STATE_USER_ACTION:
      return {
        ...state,
        ...initialState,
        showLoadingPage: state.showLoadingPage
      };
    case SHOW_ANONYMOUS_ERROR_MODAL:
      return {
        ...state,
        showAnonymousModal: !state.showAnonymousModal,
        showLoadingPage: false
      };
    case TOGGLE_LOADING_PAGE_MODAL:
      return {
        ...state,
        showLoadingPage: action.payload
      };
    case RESET_STATUS_CODE:
      return {
        ...state,
        statusCode: null,
        error: false
      };
    default:
      return state;
  }
};
