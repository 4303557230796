import styled from "styled-components";
import { colors } from "../../variables";

export const StyledForm = styled.form`
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  @media (min-width: 576px) {
    padding: 24px 32px;
  }
  @media (min-width: 1024px) {
    padding: 40px 64px;
  }
  & fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }
`;

export const FormGroup = styled.div`
  direction: ${props => (props.language === "en" ? "ltr" : "rtl")};
  margin: 8px auto;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  flex-basis: 100%;
  font-weight: inherit;
  font-family: inherit;
  color: ${props =>
    props.unsuccess
      ? colors.crimsonRed
      : props.success
      ? colors.success
      : colors.textBlack};
  & label {
    font-weight: bold;
    margin-inline-start: 4px;
    margin-block-end: 16px;
    @media (min-width: 576px) {
      font-size: 18px;
    }
  }
`;

export const StyledUserInput = styled.input`
  border: 0;
  width: 100%;
  font-size: 14px;
  padding: 12px 8px;
  font-family: inherit;
  text-align: left;
  color: ${colors.darkGrey};
  border: 2px solid ${colors.grey};
  border-radius: 12px;
  font-family: inherit;
  text-align: ${props => (props.language === "en" ? "left" : "right")};
  border-color: ${props =>
    props.unsuccess
      ? colors.crimsonRed
      : props.success
      ? colors.success
      : colors.grey};
  background-color: ${colors.textWhite};
  &::placeholder {
    font-size: inherit;
    text-align: inherit;
    color: inherit;
  }
  &:focus {
    outline: 0;
    border-color: ${colors.secondaryColor};
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media (min-width: 576px) {
    height: 60px;
    font-size: 16px;
  }
`;

export const SubmitButton = styled.button`
  background: ${props => (props.disabled ? colors.grey : colors.secondaryDark)};
  font-weight: normal;
  color: ${colors.textWhite};
  outline: 0;
  cursor: pointer;
  box-shadow: none;
  border: 0;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  bottom: 10px;
  z-index: 1;
  width: 50px;
  vertical-align: middle;
  padding: 0;
  right: ${props => (props.language === "en" ? "10px" : "")};
  left: ${props => (props.language === "en" ? "" : "10px")};
  @media (min-width: 576px) {
    right: 32px;
    bottom: 24px;
  }
  @media (min-width: 1024px) {
    right: 64px;
    height: 60px;
    width: 60px;
  }
  & img {
    max-width: 50%;
    vertical-align: inherit;
    transform: ${props =>
      props.language === "en" ? "rotate(0deg)" : "rotate(180deg)"};
    @media (min-width: 1024px) {
      max-width: 60%;
    }
  }
`;
