import { baseURL } from '../../config';
import { FETCH_MISSIONS_HISTORY } from "../constants/ActionTypes";

export const fetchMissionsHistory = () => async dispatch => {
  await baseURL.get("/history", {
    headers: {
      Authorization: `Key ${new URLSearchParams(window.location.search).get(
        "key"
      )}`
    }
  }).then(res => {
    dispatch({
      type: FETCH_MISSIONS_HISTORY,
      payload: res.data
    });
  }).catch(err => {
    // dispatch({
    //   type: ERROR_FETCH_MISSIONS
    // })
  });
};