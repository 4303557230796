import React from "react";
import { connect } from "react-redux";

import { NonsuccessContainer, Nonsuccess, TextHead, Text } from "./style.css";
import Sad from "../../../assets/images/sad.svg";

const NonsuccessModal = ({ language, message, promocode }) => {
  return (
    <>
      <NonsuccessContainer>
        <Nonsuccess src={Sad} alt="" />
      </NonsuccessContainer>
      {promocode ? (
        <TextHead>
          {language === "en" ? "Process Incomplete" : "غير مكتمل"}
        </TextHead>
      ) : (
        <TextHead>
          {language === "en" ? "Redemption Incomplete" : "غير مكتمل"}
        </TextHead>
      )}
      {promocode ? (
        <Text>
          {typeof message === "string"
            ? message
            : language === "en"
            ? message.en
            : message.ar}
        </Text>
      ) : (
        <Text>
          {language === "en"
            ? "Please try to collect points again later."
            : "يرجى المحاولة مرة أخرى في وقت لاحق"}
        </Text>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    language: state.UserInfo.language
  };
};

export default connect(mapStateToProps)(NonsuccessModal);
