import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Thunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "./redux/reducers"
import App from "./App/App";

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(Thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, document.getElementById("root"));
